<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="姓名"
          v-model="searchParam.personName"
          placeholder="请输入姓名"
        ></v-input>
        <v-input
          label="联系方式"
          v-model="searchParam.mobile"
          placeholder="请输入联系方式"
        ></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus != 1"
          @click="audit(scope.row)"
        />
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      class="edit-dialog"
      :title="dialogTitle"
      v-model="dialogFormVisible"
      sureTxt="保存"
      cancelTxt="返回"
      @confirm="submit"
      @open="OnOpen"
    >
      <form-panel
        ref="formList"
        :form="form"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="姓名"
          prop="personName"
          :rules="[
            {
              required: true,
              message: '请输入姓名',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入老年人姓名"
            clearable
            class="filter-item"
            v-model="form.personName"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="身份证号"
          prop="idCard"
          :rules="[
            {
              required: true,
              message: '请输入身份证号',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            :disabled="editStatus.isEditCard"
            placeholder="请输入老年人身份证号"
            clearable
            class="filter-item"
            v-model="form.idCard"
            @blur="handelChangeInfo('idCard')"
          ></v-input>
          <v-button v-if="form.id" text="编辑" @click="editInfo(1)"></v-button>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="mobiles"
          :rules="[
            {
              required: true,
              message: '请输入联系方式',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            :disabled="editStatus.isEditMobiles"
            placeholder="请输入联系方式"
            clearable
            class="filter-item"
            v-model="form.mobiles"
            @blur="handelChangeInfo('mobiles')"
          ></v-input>
          <v-button v-if="form.id" text="编辑" @click="editInfo(2)"></v-button>
        </el-form-item>
        <el-form-item
          label="亲属联系方式"
          prop="kinsfolkMobiles"
          :rules="[
            {
              required: true,
              message: '请输入亲属联系方式',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            :disabled="editStatus.isKinsfolkMobiles"
            placeholder="请输入亲属联系方式"
            clearable
            class="filter-item"
            v-model="form.kinsfolkMobiles"
            @blur="handelChangeInfo('kinsfolkMobiles')"
          ></v-input>
          <v-button v-if="form.id" text="编辑" @click="editInfo(3)"></v-button>
        </el-form-item>
      </form-panel>
    </v-dialog>
    <v-dialog
      v-model="isShowAudit"
      title="审核"
      @confirm="confirmAudit"
      @open="auditOpen"
    >
      <form-panel
        ref="auditForm"
        :form="auditForm"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          prop="auditStatus"
          label="审核状态"
          :rules="[
            {
              required: true,
              message: '请选择审核状态',
              trigger: 'change',
            },
          ]"
        >
          <v-select :options="auditOptions" v-model="auditForm.auditStatus">
          </v-select>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import {
  addOrUpdateInfoUrl,
  getOldPersonListUrl,
  getOldPersonDetailsUrl,
  deleteInfoUrl,
  updateInfo,
  oldPersonEdit,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "seniorManage",
  data() {
    return {
      editStatus: {
        isEditCard: false,
        isEditMobiles: false,
        isKinsfolkMobiles: false,
      },
      auditForm: {
        id: "",
        auditStatus: "",
      },
      auditOptions: [
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核不通过",
        },
      ],
      isShowAudit: false,
      form: {
        id: null,
        personName: "",
        idCard: "",
        mobiles: "",
        auditStatus: 1,
        kinsfolkMobiles: "",
      },
      dialogTitle: "新增",
      dialogFormVisible: false,
      tableUrl: getOldPersonListUrl,
      searchParam: {
        personName: null,
        mobile: null,
      },
      headers: [
        {
          prop: "personName",
          label: "姓名",
        },
        {
          prop: "eatSums",
          label: "下单次数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row.applyUserCount,
              cb: () => this.toPath(row),
            }),
        },
        {
          prop: "idCard",
          label: "身份证号",
        },
        {
          prop: "mobiles",
          label: "联系方式",
        },
        {
          prop: "kinsfolkMobiles",
          label: "亲属联系方式",
        },
      ],
    };
  },
  methods: {
    handelChangeInfo(type) {
      if (this.form.id) {
        this.$axios
          .post(oldPersonEdit, {
            id: this.form.id,
            [type]: setAESCbcEncrypt(this.form[type]),
          })
          .then((res) => {
          });
      }
    },
    //额外编辑信息
    editInfo(type) {
      type == 1
        ? ((this.form.idCard = ""), (this.editStatus.isEditCard = false))
        : type == 2
        ? ((this.form.mobiles = ""), (this.editStatus.isEditMobiles = false))
        : type == 3
        ? ((this.form.kinsfolkMobiles = ""),
          (this.editStatus.isKinsfolkMobiles = false))
        : (this.idCard = "");
    },
    toPath(data) {
      this.$router.push({
        name: "healthyMindMeals",
        query: {
          personName: data.personName,
          id: data.id,
        },
      });
    },
    back() {
      this.dialogFormVisible = false;
    },
    OnOpen() {
      this.$nextTick(() => {
        if (this.$refs.formList) {
          this.$refs.formList.clearValidate();
        }
      });
    },
    //fix打开弹出change自动检验的问题
    auditOpen() {
      this.$nextTick(() => {
        if (this.$refs.auditForm) {
          this.$refs.auditForm.clearValidate();
        }
      });
    },
    submit() {
      const result = this.$refs.formList.validate();
      if (result) {
        // if (this.form.id) {
        //   delete this.form.idCard;
        //   delete this.form.mobiles;
        //   delete this.form.kinsfolkMobiles;
        // }
        this.$axios.post(`${addOrUpdateInfoUrl}`, this.form).then((res) => {
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
      }
    },
    //审核
    audit(row) {
      this.auditForm.id = row.id;
      this.auditForm.auditStatus = "";
      this.isShowAudit = true;
    },
    //确认审核
    confirmAudit() {
      const result = this.$refs.auditForm.validate();
      if (result) {
        this.$axios
          .post(updateInfo, null, {
            params: {
              ...this.auditForm,
            },
          })
          .then((res) => {
            if (res.code == 200) {
              this.isShowAudit = false;
              this.$refs.list.search();
              this.$message.success(res.msg || "审核成功!");
            }
          });
      }
    },
    edit(item) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = item[key];
      });
      for (let key in this.editStatus) {
        this.$set(this.editStatus, key, true);
      }
      this.dialogFormVisible = true;
    },
    toAdd() {
      this.isEdit = false;
      for (let key in this.form) {
        if (key != "auditStatus") {
          this.$set(this.form, key, "");
        }
      }
      for (let key in this.editStatus) {
        this.$set(this.editStatus, key, false);
      }
      this.dialogFormVisible = true;
    },
    // 删除
    toDelete(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${deleteInfoUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
  .edit-dialog {
    .form-panel-container {
      margin: 0;
    }
    .el-form-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      ::v-deep .el-form-item__label {
        width: 25% !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        width: calc(100% - 30%);
        display: flex;
        .v-input {
          width: 75%;
          .el-input {
            width: 100% !important;
          }
        }
        .v-button {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
