var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('v-list',{ref:"list",attrs:{"isAdd":true,"headers":_vm.headers,"tableUrl":_vm.tableUrl,"searchParam":_vm.searchParam},scopedSlots:_vm._u([{key:"headBtnSlot",fn:function(){return [_c('v-button',{attrs:{"text":"新增"},on:{"click":_vm.toAdd}})]},proxy:true},{key:"searchSlot",fn:function(){return [_c('v-input',{attrs:{"label":"姓名","placeholder":"请输入姓名"},model:{value:(_vm.searchParam.personName),callback:function ($$v) {_vm.$set(_vm.searchParam, "personName", $$v)},expression:"searchParam.personName"}}),_c('v-input',{attrs:{"label":"联系方式","placeholder":"请输入联系方式"},model:{value:(_vm.searchParam.mobile),callback:function ($$v) {_vm.$set(_vm.searchParam, "mobile", $$v)},expression:"searchParam.mobile"}})]},proxy:true},{key:"operateSlot",fn:function(scope){return [(scope.row.auditStatus != 1)?_c('v-button',{attrs:{"text":"审核","type":"text"},on:{"click":function($event){return _vm.audit(scope.row)}}}):_vm._e(),_c('v-button',{attrs:{"text":"编辑","type":"text"},on:{"click":function($event){return _vm.edit(scope.row)}}}),_c('v-button',{attrs:{"text":"删除","type":"text"},on:{"click":function($event){return _vm.toDelete(scope.row)}}})]}}])}),_c('v-dialog',{staticClass:"edit-dialog",attrs:{"title":_vm.dialogTitle,"sureTxt":"保存","cancelTxt":"返回"},on:{"confirm":_vm.submit,"open":_vm.OnOpen},model:{value:(_vm.dialogFormVisible),callback:function ($$v) {_vm.dialogFormVisible=$$v},expression:"dialogFormVisible"}},[_c('form-panel',{ref:"formList",attrs:{"form":_vm.form,"hasHeader":false,"footerShow":false}},[_c('el-form-item',{attrs:{"label":"姓名","prop":"personName","rules":[
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"placeholder":"请输入老年人姓名","clearable":""},model:{value:(_vm.form.personName),callback:function ($$v) {_vm.$set(_vm.form, "personName", $$v)},expression:"form.personName"}})],1),_c('el-form-item',{attrs:{"label":"身份证号","prop":"idCard","rules":[
          {
            required: true,
            message: '请输入身份证号',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"disabled":_vm.editStatus.isEditCard,"placeholder":"请输入老年人身份证号","clearable":""},on:{"blur":function($event){return _vm.handelChangeInfo('idCard')}},model:{value:(_vm.form.idCard),callback:function ($$v) {_vm.$set(_vm.form, "idCard", $$v)},expression:"form.idCard"}}),(_vm.form.id)?_c('v-button',{attrs:{"text":"编辑"},on:{"click":function($event){return _vm.editInfo(1)}}}):_vm._e()],1),_c('el-form-item',{attrs:{"label":"联系方式","prop":"mobiles","rules":[
          {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"disabled":_vm.editStatus.isEditMobiles,"placeholder":"请输入联系方式","clearable":""},on:{"blur":function($event){return _vm.handelChangeInfo('mobiles')}},model:{value:(_vm.form.mobiles),callback:function ($$v) {_vm.$set(_vm.form, "mobiles", $$v)},expression:"form.mobiles"}}),(_vm.form.id)?_c('v-button',{attrs:{"text":"编辑"},on:{"click":function($event){return _vm.editInfo(2)}}}):_vm._e()],1),_c('el-form-item',{attrs:{"label":"亲属联系方式","prop":"kinsfolkMobiles","rules":[
          {
            required: true,
            message: '请输入亲属联系方式',
            trigger: 'blur',
          },
        ]}},[_c('v-input',{staticClass:"filter-item",attrs:{"disabled":_vm.editStatus.isKinsfolkMobiles,"placeholder":"请输入亲属联系方式","clearable":""},on:{"blur":function($event){return _vm.handelChangeInfo('kinsfolkMobiles')}},model:{value:(_vm.form.kinsfolkMobiles),callback:function ($$v) {_vm.$set(_vm.form, "kinsfolkMobiles", $$v)},expression:"form.kinsfolkMobiles"}}),(_vm.form.id)?_c('v-button',{attrs:{"text":"编辑"},on:{"click":function($event){return _vm.editInfo(3)}}}):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"title":"审核"},on:{"confirm":_vm.confirmAudit,"open":_vm.auditOpen},model:{value:(_vm.isShowAudit),callback:function ($$v) {_vm.isShowAudit=$$v},expression:"isShowAudit"}},[_c('form-panel',{ref:"auditForm",attrs:{"form":_vm.auditForm,"hasHeader":false,"footerShow":false}},[_c('el-form-item',{attrs:{"prop":"auditStatus","label":"审核状态","rules":[
          {
            required: true,
            message: '请选择审核状态',
            trigger: 'change',
          },
        ]}},[_c('v-select',{attrs:{"options":_vm.auditOptions},model:{value:(_vm.auditForm.auditStatus),callback:function ($$v) {_vm.$set(_vm.auditForm, "auditStatus", $$v)},expression:"auditForm.auditStatus"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }